import BN from 'bignumber.js'
import { fromDecimals } from './decimals'
import { fromWei } from 'web3-utils'
import { getBlockNumber } from './web3'
import { getValidatorList as commonGetValidatorList, getPastEvents as commonGetPastEvents } from '../../../../commons'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const getMaxPerTxLimit = async (contract, decimals) => {
  const maxPerTx = await contract.methods.maxPerTx().call()
  return fromDecimals(maxPerTx, decimals)
}

export const getMinPerTxLimit = async (contract, decimals) => {
  const minPerTx = await contract.methods.minPerTx().call()
  return fromDecimals(minPerTx, decimals)
}

export const getCurrentLimit = async (contract, decimals) => {
  const currentDay = await contract.methods.getCurrentDay().call()
  const dailyLimit = await contract.methods.dailyLimit().call()
  const totalSpentPerDay = await contract.methods.totalSpentPerDay(currentDay).call()
  const maxCurrentDeposit = new BN(dailyLimit).minus(new BN(totalSpentPerDay)).toString(10)
  return {
    maxCurrentDeposit: fromDecimals(maxCurrentDeposit, decimals),
    dailyLimit: fromDecimals(dailyLimit, decimals),
    totalSpentPerDay: fromDecimals(totalSpentPerDay, decimals)
  }
}

export const getPastEvents = (contract, fromBlock, toBlock, event = 'allEvents') =>
  commonGetPastEvents(contract, { fromBlock, toBlock, event })

export const getErc677TokenAddress = contract => contract.methods.erc677token().call()

export const getErc20TokenAddress = contract => contract.methods.erc20token().call()

export const getSymbol = contract => contract.methods.symbol().call()

export const getTokenTransferPerDay = async (contract, bridgeAddress, web3Provider, blocksPerDay, foreignBridge) => {
  const blockNumber = await getBlockNumber(web3Provider)
  const currentDay = await foreignBridge.methods.getCurrentDay().call()
  const events = await contract.getPastEvents('allEvents', {
    //filter: {to: bridgeAddress},
    fromBlock: blockNumber - blocksPerDay,
    toBlock: "latest"
  });
  let todayValue = 0
  await Promise.all(events.map(async (value, key, map) => {
    var block = await web3Provider.eth.getBlock(value.blockNumber)
    if (value.event == "Transfer" && value.returnValues.to == bridgeAddress && currentDay == Math.floor(block.timestamp / 86400)) {
      todayValue += Number(value.returnValues.value)
    }
  }));
  return todayValue;
}

export const getDecimals = contract => contract.methods.decimals().call()

export const getMessage = (contract, messageHash) => contract.methods.message(messageHash).call()

export const getTotalSupply = async contract => {
  const totalSupply = await contract.methods.totalSupply().call()
  const decimals = await contract.methods.decimals().call()
  return fromDecimals(totalSupply, decimals)
}

export const getBalanceOf = async (contract, address) => {
  const balance = await contract.methods.balanceOf(address).call()
  const decimals = await contract.methods.decimals().call()
  return fromDecimals(balance, decimals)
}

export const mintedTotallyByBridge = async (contract, bridgeAddress) => {
  const mintedCoins = await contract.methods.mintedTotallyByBridge(bridgeAddress).call()
  return new BN(mintedCoins)
}

export const totalBurntCoins = async contract => {
  const burntCoins = await contract.methods.totalBurntCoins().call()
  return new BN(burntCoins)
}

export const getValidatorList = async (address, eth) => commonGetValidatorList(address, eth, { logger: console })

export const getName = contract => contract.methods.name().call()

export const getFeeManager = async contract => {
  try {
    return await contract.methods.feeManagerContract().call()
  } catch (e) {
    return ZERO_ADDRESS
  }
}

export const getFeeManagerMode = contract => contract.methods.getFeeManagerMode().call()

export const getHomeFee = async contract => {
  const feeInWei = await contract.methods.getHomeFee().call()
  return new BN(fromWei(feeInWei.toString()))
}

export const getForeignFee = async contract => {
  const feeInWei = await contract.methods.getForeignFee().call()
  return new BN(fromWei(feeInWei.toString()))
}

export const getBlockRewardContract = contract => contract.methods.blockRewardContract().call()

export const getValidatorContract = contract => contract.methods.validatorContract().call()

export const getRequiredSignatures = contract => contract.methods.requiredSignatures().call()

export const getValidatorCount = contract => contract.methods.validatorCount().call()
